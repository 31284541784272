import React from "react";
import ShortThumbnail from "./ShortThumbnail";
import { onSnapshot, addDoc, doc, deleteDoc, setDoc, getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { db, shortsCollection } from "../utils/Firebase";
import { useNavigate } from "react-router-dom";
import { Button } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import { notification } from 'antd';




export default function MyShorts(props) {
    const [shorts, setShorts] = React.useState([]);
    const navigate = useNavigate();


    React.useEffect(() => {
      const fetchShorts = async () => {
        // console.log('Fetching shorts for user:', props.userProfile);
        if (!props.userProfile || !props.userProfile.uid) {
          console.error('User profile empty');
          return;
        }

          const db = getFirestore();
          const shortsCollection = collection(db, 'shorts');
          const q = query(shortsCollection, where('uid', '==', props.userProfile.uid));
          const snapshot = await getDocs(q);

          const filteredShortsArray = snapshot.docs.map((doc) => ({
            // id: doc.id,
          ...doc.data(),
          }));

      console.log('Shorts data:', filteredShortsArray);  // Add this to debug
      setShorts(filteredShortsArray);

      };

      fetchShorts();
  }, [props.userProfile]);

    // React.useEffect(() => {
    //   async function fetchShorts() {
    //     console.log('Fetching shorts for user:', props.userProfile);
    //     if (!props.userProfile || !props.userProfile.uid) {
    //       console.error('User profile is null or uid is undefined');
    //       // return;
    //     }

  
    //     try {
    //       const response = await fetch(`/api/shorts?uid=${props.userProfile.uid}`);
    //       if (!response.ok) {
    //         throw new Error('Failed to fetch shorts');
    //       }
    //       const data = await response.json();
    //       setShorts(data);
    //     } catch (error) {
    //       console.error('Error fetching shorts:', error);
    //     }
    //   }
  
    //   fetchShorts();
    //   }, [props.userProfile]);
    
    // React.useEffect(() => {
    //   const unsubscribe = onSnapshot(shortsCollection, function (snapshot) {
    //     const shortsArray = snapshot.docs.map((doc) => ({
    //       ...doc.data(), //,
    //       // id: doc.id,
    //     }));
    //     setShorts(shortsArray);
    //   });
    //   return unsubscribe;
    // }, []);

    const mapped_shorts = shorts.map((i) => {
        return (
            <ShortThumbnail
            // onClick={() => handleClick()}
            image={i.image}
            title={i.title}
            video={i.video}
            id={i.id}       // FIXED: Access id directly
            key={i.id}      // FIXED: Access id directly

            // id={i.data.id}
            // key={i.data.id}
          />
        );
      });

      function handleShare() {
        const url = `${process.env.REACT_APP_BASE_URL}/u/${props.userProfile.uid}`;

        navigator.clipboard.writeText(url).then(() => {
          notification.success({
            message: 'Link Copied',
            description: 'Link has been copied to your clipboard.',
            placement: 'topRight',
          });
        }).catch((error) => {
          console.error('Error copying URL:', error);
        });
      }
    

    

    return (
        <div className="myshorts--body">
          <div>
            <Button size="medium" className="myshorts--share-button" icon={<ShareAltOutlined />} type="default" onClick={handleShare} > 
            Share My Profile
            </Button> 
          </div>    
          <div className="app--body">
          {mapped_shorts}
          </div>
          {/* {JSON.stringify(props.userProfile)} */}

        </div>
    );
}