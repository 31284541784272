import "../styles.css";
import 'antd/dist/reset.css';
import React from "react";
import Header from "./Header";
import Body from "./Body";
import Profile from "./Profile";
import Landing from "./Landing";
import ShortViewer from "./ShortViewer";
import ViewUser from "./ViewUser";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  const [scrolled, setScrolled] = React.useState(true);

  //Set boolean to show header or not.
  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 500) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {scrolled && <Header />}
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/v/:id" element={<ShortViewer />} />
          <Route path="/u/:uid" element={<ViewUser />} />
          <Route path="/landing" element={<Landing />} />


        </Routes>
      </div>
    </BrowserRouter>
  );
}
