import React, { useState } from "react";
import Short from "./Short";
import { Collapse } from 'antd';
import { Link } from "react-router-dom"; // Import Link

export default function Landing() {
  const id = "HIYn6_oZbH1j7Rcypxonw"; // Hardcoded ID
  const [expanded, setExpanded] = useState(false);
  
  // Define static data directly in state
  const [shortValue] = React.useState([
    {
      id: "HIYn6_oZbH1j7Rcypxonw",
      createdAt: 1737338073409,
      title: "What does this website do? ",
      paywallStart: "3",
      updatedAt: 1737338073409,
      image: "https://firebasestorage.googleapis.com/v0/b/longershorts-3ed9a.appspot.com/o/thumbnails%2FHIYn6_oZbH1j7Rcypxonw?alt=media&token=ff143edb-c712-4ad5-ba1d-5d6c16ece371",
      tags: ["test", "testerson"],
      video: "https://firebasestorage.googleapis.com/v0/b/longershorts-3ed9a.appspot.com/o/shorts%2FHIYn6_oZbH1j7Rcypxonw?alt=media&token=cb73ba5c-c53f-42fe-8a90-13494c0b1422",
      user: "jamesmarlowww@gmail.com",
      stripeAccountId: "acct_1QeSMSCXbwHg2Cii",
      uid: "NcWm-nfs2A8fUMcuYa2U9",
      email: "jamesmarlowww@gmail.com"
    }
  ]);

  const toggleTerms = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="landing-page">
      {/* Video Section */}
      <div className="short--viewer">
        {shortValue.length > 0 && shortValue[0].id != null ? (
          <div>
            <Short
              image={shortValue[0].image}
              title={shortValue[0].title}
              video={shortValue[0].video}
              id={id}
              key={id}
              paywallStart={shortValue[0].paywallStart}
              uid={shortValue[0].uid}
              stripe_account_id={shortValue[0].stripeAccountId}
              email={shortValue[0].email}
            />
          </div>
        ) : (
          <h1>Loading featured content...</h1>
        )}
      </div>

      {/* Information Section */}
      <div className="info-section">
        <h2>How LongerShorts Works</h2>
        
        <div className="info-cards">
          <div className="info-card">
            <h3>For Creators</h3>
            <p>✅ Free to upload your content</p>
            <p>✅ Set paywalls in your videos</p>
            <p>✅ Earn from subscriptions</p>
            <p>✅ Current platform fee: 25%</p>
            <Link to="/profile">
              <button className="cta-button">Start Creating</button>
            </Link>
          </div>
          
          <div className="info-card">
            <h3>For Viewers</h3>
            <p>✅ Browse free preview content</p>
            <p>✅ $3 USD monthly per creator</p>
            <p>✅ Subscribe to multiple creators</p>
            <p>✅ Cancel anytime</p>
            <Link to="/">
              <button className="cta-button">Sign Up</button>
            </Link>
          </div>
        </div>

        <div className="pricing-details">
          <h3>Pricing Details</h3>
          <p>Current subscription price: <strong>$3.00 USD per month</strong> per creator</p>
          <p>Platform fee: <strong>25%</strong> (This fee will be reduced as our platform grows)</p>
          <p>Creator earnings: <strong>75%</strong> of subscription revenue</p>
          
          <div className="future-plans">
            <h4>Future Plans</h4>
            <p>We're continuously working to improve LongerShorts:</p>
            <ul>
              <li>Custom subscription pricing for creators</li>
              <li>Lower platform fees as we scale</li>
              <li>Enhanced creator analytics</li>
            </ul>
          </div>
        </div>
        
        {/* Terms and Conditions Section */}
        <div className="terms-section">
          <h3 onClick={toggleTerms} className="terms-header">
            Terms and Conditions {expanded ? '▲' : '▼'}
          </h3>
          
          {expanded && (
            <div className="terms-content">
              <h4>User Agreement</h4>
              <p>By using LongerShorts, you agree to the following terms and conditions:</p>
              <ol>
                <li><strong>Content Ownership:</strong> Creators retain ownership of their content.</li>
                <li><strong>Subscription Terms:</strong> Subscriptions are billed monthly and can be canceled at any time.</li>
                <li><strong>Refund Policy:</strong> Subscriptions are non-refundable once content has been accessed.</li>
                <li><strong>Prohibited Content:</strong> Users may not upload illegal, offensive, or copyrighted content without permission.</li>
                <li><strong>Payment Processing:</strong> All payments are processed securely through Stripe.</li>
                <li><strong>Platform Fee:</strong> LongerShorts currently takes a 25% fee from subscription revenue.</li>
                <li><strong>Account Termination:</strong> LongerShorts reserves the right to terminate accounts that violate our terms.</li>
                <li><strong>Changes to Service:</strong> We may modify the service and pricing structure with reasonable notice.</li>
              </ol>
              
              <h4>Privacy Policy</h4>
              <p>LongerShorts collects and processes personal data in accordance with applicable laws. For more information, please refer to our complete Privacy Policy.</p>
              
              {/* <button className="secondary-button">Read Full Terms</button> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
